


function makeSearch(inputText, selectPor) {
    var c = $.trim(inputText);
    if (c !== "") // si escribio algo en el textbox
        window.location = "/resultados.aspx?c=" + encodeURI(c);

}


$(document).ready(function () {
    $('li.logout').logOut();
    $('.suscribe').suscribe();
    ///Usado para bootstrap
    jQuery.validator.setDefaults({
        highlight: function (element) {
            jQuery(element).closest('.form-group').addClass('has-error');
        },
        unhighlight: function (element) {
            jQuery(element).closest('.form-group').removeClass('has-error');
        },
        errorElement: 'span',
        errorClass: 'label label-danger',
        errorPlacement: function (error, element) {
            if (element.parent('.input-group').length) {
                error.insertAfter(element.parent());
            } else {
                error.insertAfter(element);
            }
        }
    });


    //if (!$.cookie("tipo_precio")) {
    //    autoDetectConfig();
    //}
    //cargarDDLPais();

    //$('.dropdown-currency .dropdown-item').on("click", function (e) {
    //    var codigo = $(this).attr('value');
    //    cambiarPais(codigo);
    //});

    $("img.lazy").lazyload(
        {
            data_attribute: "orig"
        });

    ///Efecto al presionar en Hamburguer
    $(".navbar-toggle").on("click", function () {
        $(this).toggleClass("active");
    });

    $('.carrito').carritoRefresh({ tipoProducto: 'ITEM', currency: '$' });
    $('a.comprar.fisico').comprarItem();
    $('a.comprarMasivo.fisico').comprarItemMasivo();
    $('i.btnPlus').pressBtnPlus();
    $('i.btnMinus').pressBtnMinus();
    $('i.btnTrash').pressBtnTrash();
    $('input.btnKeyPress').numberValidate();
    $('a.comprar.ebook, a.regalar.ebook').comprarItemDigital();
    $('.overflow-show').overflowShowMore();
    $('.whishlist').wishList({ tipoProducto: 'ITEM', titulo: 'Agregar a mi lista de deseos' });
    $('li.clear').clearCache();
    $('a.clear').clearCache();

    //$('.suscribe').suscribe();
    $('#searchMobile').on("click", function () {
        $(".buscadorMobile").toggle();
        $("#searchMobile").toggleClass("active");
    });

    $('.buscador .dropdown-menu a').click(function () {
        $(this).parentsUntil(".buscador").find("button.dropdown-toggle").html($(this).text());
    });

    $('.buscador .btn-search').unbind('click');
    $('.buscador .btn-search').click(function () {
        var inputText = $(this).parents('.buscador').find('input#texttosearch');
        makeSearch(inputText.val());
    });
    $('.verMasColeccionesMobile').click(function (event) {
        event.preventDefault();
        $('.coleccionesPrincipales ul li').show(200);
        $(this).hide();
    });


    //$('.col-md-12.cont-central .vidriera .slick-slider').slick({
    //    dots: false,
    //    autoplay: true,
    //    infinite: true,
    //    speed: 250,
    //    autoplaySpeed: 3000,
    //    slidesToShow: 4,
    //    slidesToScroll: 1,
    //    responsive: [{ breakpoint: 1024, settings: { slidesToShow: 4, slidesToScroll: 2 } },
    //    { breakpoint: 801, settings: { slidesToShow: 3, slidesToScroll: 1 } },
    //    { breakpoint: 480, settings: { slidesToShow: 2, slidesToScroll: 1 } }
    //    ]
    //});

    //if ($.tap.IsTablet()) {
    //    console.log('testing');

    //    $('.col-md-12.cont-central .col-md-7 .vidriera .slick-slider').slick({
    //        dots: true,
    //        autoplay: true,
    //        infinite: true,
    //        speed: 150,
    //        slidesToShow: 6,

    //        slidesToScroll: 1,
    //        responsive: [{ breakpoint: 1024, settings: { slidesToShow: 6, slidesToScroll: 4 } },
    //        { breakpoint: 801, settings: { slidesToShow: 3, slidesToScroll: 1 } },
    //        { breakpoint: 480, settings: { slidesToShow: 1, slidesToScroll: 1 } }
    //        ]
    //    });

    //    $('.widgetHomeContenedor .row').slick({
    //        dots: false,
    //        autoplay: true,
    //        infinite: true,
    //        speed: 150,
    //        slidesToShow: 1,
    //        slidesToScroll: 1,
    //        responsive: [{ breakpoint: 1024, settings: { slidesToShow: 1, slidesToScroll: 1 } },
    //        { breakpoint: 801, settings: { slidesToShow: 1, slidesToScroll: 1 } },
    //        { breakpoint: 480, settings: { slidesToShow: 1, slidesToScroll: 1 } }
    //        ]
    //    });
    //    //tablet
    //    $('.col-md-12.cont-central .ofertas.vidriera .slick-slider').slick({
    //        dots: false,
    //        autoplay: true,
    //        infinite: true,
    //        speed: 150,


    //        slidesToShow: 4,
    //        slidesToScroll: 1,
    //        responsive: [{ breakpoint: 1024, settings: { slidesToShow: 4, slidesToScroll: 2 } },
    //        { breakpoint: 801, settings: { slidesToShow: 3, slidesToScroll: 1 } },
    //        { breakpoint: 480, settings: { slidesToShow: 1, slidesToScroll: 1 } }
    //        ]
    //    });
    //    console.log('cargó script tablet2');
    //    $('.contenedorDestacados .vidriera .slick-slider').slick({
    //        dots: true,
    //        autoplay: true,
    //        infinite: true,
    //        speed: 150,
    //        centerMode: true,
    //        slidesToShow: 1,
    //        slidesToScroll: 1,
    //        responsive: [{ breakpoint: 1024, settings: { slidesToShow: 1, slidesToScroll: 1 } },
    //        { breakpoint: 801, settings: { slidesToShow: 1, slidesToScroll: 1 } },
    //        { breakpoint: 480, settings: { slidesToShow: 1, slidesToScroll: 1 } }
    //        ]
    //    });
    //    $('.col-md-12.cont-central .vidriera .slick-slider').slick({
    //        dots: true,
    //        autoplay: true,
    //        infinite: true,
    //        centerMode: true,
    //        speed: 150,
    //        slidesToShow: 6,
    //        slidesToScroll: 1,
    //        responsive: [{ breakpoint: 1024, settings: { slidesToShow: 6, slidesToScroll: 4 } },
    //        { breakpoint: 801, settings: { slidesToShow: 3, slidesToScroll: 1 } },
    //        { breakpoint: 480, settings: { slidesToShow: 2, slidesToScroll: 2 } }
    //        ]
    //    });
    //}
    //else if ($.tap.IsMobile()) {

    //    $('.col-md-12.cont-central .col-md-7 .vidriera .slick-slider').slick({
    //        dots: true,
    //        autoplay: true,
    //        infinite: true,
    //        speed: 150,
    //        slidesToShow: 6,

    //        slidesToScroll: 1,
    //        responsive: [{ breakpoint: 1024, settings: { slidesToShow: 6, slidesToScroll: 4 } },
    //        { breakpoint: 801, settings: { slidesToShow: 3, slidesToScroll: 1 } },
    //        { breakpoint: 480, settings: { slidesToShow: 1, slidesToScroll: 1 } }
    //        ]
    //    });



    //    $('.contenedorDestacados .vidriera .slick-slider').slick({
    //        dots: true,
    //        autoplay: true,
    //        infinite: true,
    //        speed: 150,
    //        centerMode: true,
    //        slidesToShow: 1,
    //        slidesToScroll: 1,
    //        responsive: [{ breakpoint: 1024, settings: { slidesToShow: 4, slidesToScroll: 1 } },
    //        { breakpoint: 801, settings: { slidesToShow: 3, slidesToScroll: 1 } },
    //        { breakpoint: 480, settings: { slidesToShow: 1, slidesToScroll: 1 } }
    //        ]
    //    });

    //    $('.col-md-12.cont-central .ofertas.vidriera .slick-slider').slick({
    //        dots: false,
    //        autoplay: true,
    //        infinite: true,
    //        speed: 150,
    //        centerMode: true,

    //        slidesToShow: 1,
    //        slidesToScroll: 1,
    //        responsive: [{ breakpoint: 1024, settings: { slidesToShow: 4, slidesToScroll: 2 } },
    //        { breakpoint: 801, settings: { slidesToShow: 3, slidesToScroll: 1 } },
    //        { breakpoint: 480, settings: { slidesToShow: 1, slidesToScroll: 1 } }
    //        ]
    //    });


    //    $('.col-md-12.cont-central .vidriera .slick-slider').slick({
    //        dots: true,
    //        autoplay: true,
    //        infinite: true,
    //        centerMode: true,
    //        speed: 150,
    //        slidesToShow: 6,
    //        slidesToScroll: 1,
    //        responsive: [{ breakpoint: 1024, settings: { slidesToShow: 6, slidesToScroll: 4 } },
    //        { breakpoint: 801, settings: { slidesToShow: 3, slidesToScroll: 1 } },
    //        { breakpoint: 480, settings: { slidesToShow: 1, slidesToScroll: 1 } }
    //        ]
    //    });
    //}
    //else {

    //    //desktop



    //    $('.col-md-12.cont-central .vidriera .slick-slider').slick({
    //        dots: true,
    //        autoplay: true,
    //        infinite: true,
    //        centerMode: false,
    //        speed: 150,
    //        slidesToShow: 6,
    //        slidesToScroll: 1,
    //        responsive: [{ breakpoint: 1024, settings: { slidesToShow: 6, slidesToScroll: 4 } },
    //        { breakpoint: 801, settings: { slidesToShow: 3, slidesToScroll: 1 } },
    //        { breakpoint: 480, settings: { slidesToShow: 1, slidesToScroll: 1 } }
    //        ]
    //    });

    //    $('.col-md-12.cont-central .col-md-7 .vidriera .slick-slider').slick({
    //        dots: true,
    //        autoplay: true,
    //        infinite: true,
    //        speed: 150,
    //        slidesToShow: 6,

    //        slidesToScroll: 1,
    //        responsive: [{ breakpoint: 1024, settings: { slidesToShow: 6, slidesToScroll: 4 } },
    //        { breakpoint: 801, settings: { slidesToShow: 3, slidesToScroll: 1 } },
    //        { breakpoint: 480, settings: { slidesToShow: 1, slidesToScroll: 1 } }
    //        ]
    //    });


    //}



    $('.col-sm-12.cont-central .vidriera .slick-slider').slick({
        dots: false,
        autoplay: true,
        infinite: false,
        centerMode: false,
        speed: 150,
        slidesToShow: 6,
        slidesToScroll: 1,
        responsive: [
            { breakpoint: 1400, settings: { slidesToShow: 6, slidesToScroll: 1 } },
            { breakpoint: 1200, settings: { slidesToShow: 5, slidesToScroll: 1 } },
            { breakpoint: 992, settings: { slidesToShow: 4, slidesToScroll: 1 } },
            { breakpoint: 768, settings: { slidesToShow: 3, slidesToScroll: 1 } },
            { breakpoint: 640, settings: { slidesToShow: 2, slidesToScroll: 1 } },
            { breakpoint: 480, settings: { slidesToShow: 1, slidesToScroll: 1 } }
        ]
    });




    $('.pagina-categorias .cont-central .vidriera .slick-slider').slick({
        dots: false,
        autoplay: true,
        infinite: false,
        speed: 150,
        slidesToShow: 5,
        slidesToScroll: 1,
        responsive: [{ breakpoint: 1024, settings: { slidesToShow: 5, slidesToScroll: 4 } },
        { breakpoint: 801, settings: { slidesToShow: 2, slidesToScroll: 1 } },
        { breakpoint: 480, settings: { slidesToShow: 1, slidesToScroll: 1 } }
        ]
    });


    $('.pagina-listado-locales .slick-slider').slick({
        dots: false,
        autoplay: true,
        infinite: false,
        speed: 150,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [{ breakpoint: 1024, settings: { slidesToShow: 3, slidesToScroll: 1 } },
        { breakpoint: 801, settings: { slidesToShow: 2, slidesToScroll: 1 } },
        { breakpoint: 480, settings: { slidesToShow: 1, slidesToScroll: 1 } }
        ]
    });






    $('.buscador input#texttosearch').keydown(function (event) {
        if (event.keyCode === 13) {
            makeSearch($(this).val());
        }
    }).keyup(function (event) {
        if (event.keyCode === 13) {
            makeSearch($(this).val());
        }
    }).autocomplete({
        source: "/handlers/autoComplete.ashx",
        selectFirst: false,
        delay: 2,
        select: function (event, ui) { window.location = "/resultados.aspx?c=" + encodeURI(ui.item.value) + "&por=pal"; }
    });



    $('.md-conversor a').conversor();

});

// Replace source
handleImageNotFound = function (el) {
    // $(el).attr('src', '/images/noimage.jpg');
}


autoDetectConfig = function () {
    var tipo = 9;
    var codigo = "COP";
    var code = $.tap.GetPaisPorIp();
    if (code === "CO") {
        tipo = 9;
        codigo = "COP";
    }
    else {
        tipo = 10;
        codigo = "USD";
    }

    cambiarRegion(codigo, tipo);
    if (!window.location.host.includes('.localhost.'))
        window.location.reload();
}


cambiarPais = function (codigo) {
    var region = 9;
    if (codigo === "USD") {
        region = 10;
    }

    cambiarRegion(codigo, region);
    window.location.href = "/cambiarubicacion.aspx?pais=" + codigo + "&vuelvo=" + window.location.href;
}

cargarDDLPais = function () {
    var pais = $.cookie("pais");
    if (pais) {
        var codigo = pais;
        $(".dropdown-currency button").html(codigo);
    }
    else
        $(".ddl_cambiarubicacion").val("USD");
}

cambiarRegion = function (pais, region) {
    //$.cookie("pais", pais);
    //$.cookie("tipo_precio", region);
    document.cookie = "pais=" + pais + "; path=/; secure; samesite=strict;";

    document.cookie = "tipo_precio=" + region + "; path=/; secure; samesite=strict;";
    //window.location = window.location;
}


equalheight = function (container) {

    var currentTallest = 0,
        currentRowStart = 0,
        rowDivs = new Array(),
        $el,
        topPosition = 0;
    $(container).each(function () {

        $el = $(this);
        $($el).height('auto');
        topPostion = $el.position().top;

        if (currentRowStart !== topPostion) {
            for (currentDiv = 0; currentDiv < rowDivs.length; currentDiv++) {
                rowDivs[currentDiv].height(currentTallest);
            }
            rowDivs.length = 0; // empty the array
            currentRowStart = topPostion;
            currentTallest = $el.height();
            rowDivs.push($el);
        } else {
            rowDivs.push($el);
            currentTallest = (currentTallest < $el.height()) ? ($el.height()) : (currentTallest);
        }
        for (currentDiv = 0; currentDiv < rowDivs.length; currentDiv++) {
            rowDivs[currentDiv].height(currentTallest);
        }
    });
}